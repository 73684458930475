import {
  Challenge,
  AccessType,
} from '@wix/ambassador-challenges-v1-challenge/types';

export function isPrivateChallenge(challenge: Challenge) {
  return (
    challenge?.settings?.accessRestrictions?.accessType === AccessType.PRIVATE
  );
}
